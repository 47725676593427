@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap");

.Container2 {
  background: #100a1c;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  width: 100vw;
  height: inherit;
}

.Container2-1 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding:30px 0px;
}

.laranja {
  color: rgb(194, 122, 59);
}

.Img-Div{
  z-index: 1!important;
}

.Mi {
  width: 10rem;
  border-radius: 50%;
  z-index: 1 !important;
}

.Text-Div {
  margin: 0px 30px;
  color:#836554;
  letter-spacing: 0.1rem;
  font-size: 1.1rem;
}

.Text-Div-2 {
  margin-top: -20px;
  padding-bottom: 20px;
  padding: 20px;
  background-color: rgb(40, 39, 39, 0.4);
  border-radius: 20px;
  line-height: 1.2rem;
  animation-name: textodiv2;
  animation-direction: reverse;
  animation-duration: 1s;
  overflow: hidden;
  z-index: -1;
  font-size: 0.9rem;
}

@keyframes textodiv2 {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(220px);
  }
}

@keyframes sobremim {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-400px);
  }
}

@media screen and (min-width: 768px) {
  .App {
    max-width: 100vw;
  }

  .thirdsec {
    height: 95vh !important;
    display: flex;
    flex-direction: row;
    padding: 25px;
    max-width: 100%;
  }

  .Container3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 50%;
  }

  .Text-Div-3 {
    height: 70%;
    width: 50%;
    
  }

  .Container {
    display: flex;
    justify-content: space-around;
    height: 85vh;
  }

  .ContainerFilho {
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
  }

  .Chilling {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Container-1 {
    font-size: 1em;
    height: 100px;
  }
  .Container-2 {
    font-size: 0.8em;
    height: 55%;
    display: flex;

    flex-direction: column;
  }
  .Container-2 Button {
    margin-top: 2%;
    height: 50px;
  }
  .Container-2 h5 {
    margin: 0px 5px 15px 0px;
  }

  .Container2 {
    height: 85vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow: hidden !important;
  }
  .Text.Div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Text-Div-2 {
    max-width: 700px;
  }

  .Container4{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:40px;
    padding-bottom: 40px;
  }

  
}
