@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap");


.app{
  max-width: 100vw;
}
.Container {
  font-family: "Roboto", sans-serif;
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: linear-gradient(-1deg, #eeeeee, #ffffff);
  background-size: 100% 100%;
  color: rgb(70, 69, 69) !important;
}

.ContainerFilho{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes mymove {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(400px);
  }
}

.Container-1 {
  width: 78%;
  height: 50%;
  margin: 0 auto;
}

.Logo {
  width: 2.8em;
}
.Logo-Div {
  margin-top: 2%;
}

.Name-Div {
  font-size: 0.7rem;
}

.Name-Div h1 {
  margin: 0;
  padding: 0;
}

.Function-Div {
  font-size: 0.4rem;
}

.Function-Div h1 {
  margin: 0;
  padding: 0;
}

.Container-2 {
  margin: 0 auto;
  width: 78%;
  height: 55%;
  animation-name: mymove;
  animation-direction: reverse;
  animation-duration: 1s;
  overflow: hidden;
  font-size: 0.8rem;
  padding-bottom: 50px;
      
}

.Container-2 h5 {
  font-size: 1rem;
  font-weight: 300;
}
.Button-Agendamento {
  text-decoration: none;
  background-color: rgb(194, 122, 59);
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  height: 60px;
  border-radius: 1rem;
  color: white;
}

.Button-Agendamento svg {
  margin-left: 50%;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: rgba(0, 0, 0, 0.946) !important;
}

.MuiIconButton-root svg {
  font-size: 3rem;
}

.Button-Agendamento span {
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
}

.MuiBottomNavigation-Div {
  background-color: transparent;

  font-size: 1rem;
}

.MuiBottomNavigation-root {
  padding: 0px 0px;
  font-size: 1.3rem;
}

.MuiBottomNavigationAction-label {
  font-size: 1rem !important;
  color: white;
  background-color: black;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.Container2 {
  background-color: black;
  height: 100vh;
}

@media screen and (min-width: 768px){

    .App{
      max-width: 100vw;
    }
    .Container{
        
        display: flex;
        justify-content: space-around;
        height: 85vh;
        max-width: 100%;
        
       
        
    }

    .ContainerFilho{
        height: 100%;
        width: 60%;
        display: flex;
        justify-content: space-around;
        padding-top: 50px;
        overflow: hidden;

        
    }

    .Chilling{
      
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Container-1{
      font-size: 1em;
      height: 100px;
    }
    .Container-2{
      font-size: 0.8em;
      height: 55%;
      display: flex;
      
      flex-direction: column;
    }
    .Container-2 Button{
      margin-top: 2%;
      height: 50px;
    }
    .Container-2 h5 {
      margin: 0px 5px 15px 0px;
    }


    .Container2{
       height: 85vh !important;
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .Text.Div{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Text-Div-2{
      max-width: 700px
    }


    .thirdsec{
      height: 85vh !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
}

