.Container4 {
  background-color: rgb(194, 122, 59);
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:
      radial-gradient(27% 29% at right, #0000 83%,#000000 85% 99%,#0000 101%) calc(32px/2) 32px,
      radial-gradient(27% 29% at left, #0000 83%,#000000 85% 99%,#0000 101%) calc(32px/-2) 32px,
      radial-gradient(29% 27% at top, #0000 83%,#000000 85% 99%,#0000 101%) 0 calc(32px/2),
      radial-gradient(29% 27% at bottom, #0000 83%,#000000 85% 99%,#0000 101%) 0 calc(32px/-2)
      #100a1c;
background-size: 64px 64px;


}

.kXteup:hover:enabled,
.kXteup:focus:enabled {
  background-color: rgb(194, 122, 59) !important;
}

.MuiCardContent-root {
  background-color: #3131323e;
  width: 300px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: inherit;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .App {
    max-width: 100vw;
  }

  .thirdsec {
    height: 95vh !important;
    display: flex;
    flex-direction: row;
    padding: 25px;
  }

  .Container3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 50%;
  }

  .Text-Div-3 {
    height: 70%;
    width: 50%;
    
  }

  .Container {
    display: flex;
    justify-content: space-around;
    height: 85vh;
  }

  .ContainerFilho {
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
  }

  .Chilling {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Container-1 {
    font-size: 1em;
    height: 100px;
  }
  .Container-2 {
    font-size: 0.8em;
    height: 55%;
    display: flex;

    flex-direction: column;
  }
  .Container-2 Button {
    margin-top: 2%;
    height: 50px;
  }
  .Container-2 h5 {
    margin: 0px 5px 15px 0px;
  }

  .Container2 {
    height: 85vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Text.Div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Text-Div-2 {
    max-width: 700px;
  }

  .Container4{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:40px;
    padding-bottom: 40px;
  }

  
}
