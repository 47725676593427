@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap");

.thirdsec {
  height: 85vh;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: roboto, sans-serif;
  /* background-color: #e8e8e8; */
  background-color: #f4f4f4;
  color: #666a74;
}

.Container3 {
  width: 90%;
  height: 40vh;
  margin: 20px;
}

.laranja {
  color: rgb(194, 122, 59);
}

.Text-Div-3 {
  line-height: 1.5rem;
  font-size: 0.9rem;
  padding: 0px 20px;
  margin: 20px;
  background-color: #e8e8e8;
}

@media screen and (min-width: 768px) {
  .App {
    max-width: 100vw;
  }

  .thirdsec {
    height: 95vh !important;
    display: flex;
    flex-direction: row;
    padding: 0!important;
  }

  .Container3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 50%;
  }

  .Text-Div-3 {
    height: 70%;
    width: 50%;
    
  }

  .Container {
    display: flex;
    justify-content: space-around;
    height: 85vh;
  }

  .ContainerFilho {
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
  }

  .Chilling {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Container-1 {
    font-size: 1em;
    height: 100px;
  }
  .Container-2 {
    font-size: 0.8em;
    height: 55%;
    display: flex;

    flex-direction: column;
  }
  .Container-2 Button {
    margin-top: 2%;
    height: 50px;
  }
  .Container-2 h5 {
    margin: 0px 5px 15px 0px;
  }

  .Container2 {
    height: 85vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Text.Div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Text-Div-2 {
    max-width: 700px;
  }
}
